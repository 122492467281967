/*************************
    Keycloak Override
**************************/
/* Do not display username and reset form (which doesn't work due to missing patternfly) on profile editors top */
#kc-username {
    display: none;
}


/*************************
    Custom Styling
**************************/
.bosch-profile-form {
    margin: 0;
}

.bosch-profile-form h6 {
    font-family: BoschSansLight;
}

.scopeHeader {
    /* margin-bottom: 20px; */
}

.formGroup {
    padding: .5rem;
}

.radioList {
    display: flex;
    flex-direction: column;
}

.radioList.error {
    box-shadow: 0 1px 0 0 #EA0016;
    transition: box-shadow 150ms ease-out;
    margin-bottom: .25rem;
}

/* show the alert fields only at the login */
.alert {
    display: flex;
}

/* the profile page has its own error presentation */
.login-rb-accounts .alert {
    display: flex;
}
